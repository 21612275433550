<template>
  <v-card class="ma-2">
    <v-row class="px-2"> </v-row>
    <v-row class="px-2">
      <v-col class="d-flex flex-column justify-center align-center">
        <v-avatar size="300">
          <v-img
            lazy-src="@/assets/nopic.jpg"
            class="d-flex justify-center"
            id="avatar"
            :src="picture"
            refs="avatar"
          ></v-img>
        </v-avatar>
        <Dialog @base64="img64($event)" class="mx-1" />
        <v-file-input
          v-model="user.picture"
          placeholder="Upload da sua foto"
          prepend-icon="mdi-image-area"
          @change="pickFile"
        >
        </v-file-input>
      </v-col>
      <v-col>
        <v-col>
          <v-text-field
            label="Usuário"
            disabled
            v-model="user.userName"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Nome"
            hint="Digite a seu nome"
            v-model="user.name"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Senha Atual"
            hint="Digite a senha atual"
            v-model="user.currPass"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Nova Senha"
            hint="Digite a nova senha"
            v-model="user.password"
          ></v-text-field>
        </v-col>
      </v-col>
    </v-row>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="send">Enviar Alterações</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// import { mapGetters } from 'vuex';
import Dialog from "../Webcam/WbDialog";
export default {
  components: {
    Dialog,
  },
  data: () => {
    return {
      currPass: "",
      senha: "",
      confirmacao: "",
      picture: "",
      file: null,
      user: {},
    };
  },
  methods: {
    img64(IMGbase64) {
      this.picture = IMGbase64;
      this.file = this.dataURItoBlob(this.picture);
    },
    dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);
      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      // let blob = new Blob([ia], { type: mimeString });
      // blob.lastModifiedDate = new Date();
      // blob.name = `WebCam ${new Date().toDateString()}.${mimeString.split('/')[1]}`;

      // return new Blob([ia], { type: mimeString });
      return new File(
        [ia],
        `WebCam ${new Date().toDateString()}.${mimeString.split("/")[1]}`
      );
    },

    send() {
      let user = this.user;
      let formData = new FormData();

      for (const key in user) {
        if (Object.hasOwnProperty.call(user, key)) {
          if (user[key]) {
            formData.append(key, user[key]);
          }
        }
      }
      this.$http
        .put("/usuario", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          if (resp.status < 299) {
            this.$store.dispatch("resetSnack");
            this.$store.dispatch("showSuccessSnack", "Alterações Aplicadas");
            this.picture = resp.data.picture;
            this.$store.commit("setUser", resp.data);
          }
        });
    },
    pickFile() {
      let file = this.user.picture;
      if (file) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.picture = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
  },
  created() {
    this.user = { ...this.$store.state.user };
    if (this.$store.state.user.picture) {
      this.picture = this.$store.state.user.picture;
    }
    delete this.user.picture;
  },
};
</script>

<style>
</style>